import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PasswordRules } from './passwordRules';
import useNetworkConnection from '../library/NetworkConnection/NetworkConnection';
import InputField from '../library/inputField/inputField';
import Button from '../library/Button/button';
// this a reusable component .
interface PasswordSetupFormProps {
  password: string;
  confirmPassword: string;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onConfirmPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  passwordError?: string;
  confirmPasswordError: string;
  passwordShown: boolean;
  togglePassword: (e: any) => void;
  confirmPasswordShown: boolean;
  toggleConfirmPassword: (e: any) => void;
  formValid: boolean;
  buttonText: string;
  buttonText1: string;
  buttonName: string;
  otp?: string;
  otpError?: string;
  onOtpChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordChangeForm: React.FC<PasswordSetupFormProps> = ({
  password,
  confirmPassword,
  onPasswordChange,
  onConfirmPasswordChange,
  passwordError,
  confirmPasswordError,
  passwordShown,
  togglePassword,
  confirmPasswordShown,
  toggleConfirmPassword,
  formValid,
  buttonText,
  buttonText1,
  buttonName,
  otp,
  otpError,
  onOtpChange
}) => {
  const [showMeter, setShowMeter] = useState(false);

  const location = useLocation();
  // Reusable hook to check internet connectivity
  const { isConnected } = useNetworkConnection();

  const showOtp = location.pathname === '/admin' || location.pathname === '/user';

  return (
    <div>
      {isConnected && (
        <div className="w-full">
          <Link
            className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
            onClick={(e) => togglePassword(e)}
            to={''}
          >
            {buttonText}
          </Link>
          <div className="mt-2">
            {passwordError && <p className="text-red-500 text-xs">{passwordError}</p>}
          </div>
          <InputField
            id={'newPassword'}
            className={`h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
              passwordError ? 'border-red' : ''
            }`}
            type={passwordShown ? 'text' : 'password'}
            placeholder="Enter your new password"
            value={password}
            onChange={onPasswordChange}
            onFocus={() => setShowMeter(true)}
            onBlur={() => setShowMeter(false)}
          />
          {showMeter && (
            <div>
              <PasswordStrengthBar minLength={16} password={password} />
              <div className="rules truncate">
                <ul>
                  {PasswordRules(password).map((rule) => (
                    <li key={rule.id} className={rule.isValid ? 'rule-passed' : 'rule-failed'}>
                      {rule.isValid ? (
                        <>
                          <span data-check>
                            <svg
                              className="flex-shrink-0 w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                        </>
                      ) : (
                        <>
                          <span data-uncheck>
                            <svg
                              className="flex-shrink-0 w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </span>
                        </>
                      )}
                      <div className="pl-[20px] mt-[-1.3rem] pb-[5px] truncate">
                        {rule.description}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <Link
            className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
            onClick={(e) => toggleConfirmPassword(e)}
            to={''}
          >
            {buttonText1}
          </Link>
          <div className="mt-2">
            {confirmPasswordError && <p className="text-red-500 text-xs">{confirmPasswordError}</p>}
          </div>
          <InputField
            id={'confirmPassword'}
            className={`h-7 w-full p-1.5 mb-4 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
              confirmPasswordError ? 'border-red' : ''
            }`}
            type={confirmPasswordShown ? 'text' : 'password'}
            placeholder="Enter password again"
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
          />
          {showOtp ? (
            <div>
              <div className="mt-2">
                {otpError && <p className="text-red-500 text-xs">{otpError}</p>}
              </div>
              <InputField
                id={'otp'}
                className={`h-7 w-full p-1.5 mb-4 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
                  otpError ? 'border-red' : ''
                }`}
                type="number"
                placeholder="Enter OTP from authenticator"
                value={otp}
                onChange={onOtpChange}
              />
            </div>
          ) : (
            <div className="hidden">
              <div className="mt-2">
                {otpError && <p className="text-red-500 text-xs">{otpError}</p>}
              </div>
              <InputField
                id={'otp'}
                className={`h-7 w-full p-1.5 mb-4 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
                  otpError ? 'border-red' : ''
                }`}
                type="number"
                placeholder="Enter OTP from authenticator"
                value={otp}
                onChange={onOtpChange}
              />
            </div>
          )}
          <Button
            className={
              formValid
                ? 'bg-black  text-white border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
                : 'bg-[#808080] cursor-not-allowed  text-white border-[#808080] opacity-50 py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
            }
            disabled={!formValid}
            buttonText={`${buttonName}`}
          />
        </div>
      )}
    </div>
  );
};

export default PasswordChangeForm;
