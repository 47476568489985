import React from 'react';
import Label from '../label/label';

const ValidatedInputField = (props: {
  label: string;
  id: string;
  type: string;
  placeholder: string;
  register?: any;
  errors?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: string;
  style?: React.CSSProperties;
  className: string;
  requiredField: string;
  maxLength?: number;
  validEmailDomain?: boolean;
}) => {
  const {
    label,
    id,
    type,
    placeholder,
    register,
    errors,
    onChange,
    disabled,
    value,
    style,
    className,
    requiredField,
    maxLength,
    validEmailDomain
  } = props;

  const showGivenError = typeof errors === 'string' && errors.length;

  return (
    <div>
      <Label className={'inline mb-0 mt-2'}>
        {type === 'email' && !validEmailDomain ? (
          <span className="pr-[55rem] required-field">Please use proper email domain</span>
        ) : errors === undefined ? (
          <span className={`pr-[61rem] ${requiredField}`}>{label}</span>
        ) : showGivenError ? (
          <span className="required-field">{errors}</span>
        ) : (
          <span className="pr-[55rem] required-field">Please enter your {label}</span>
        )}
      </Label>
      <br />
      <input
        id={id}
        className={className}
        type={type}
        placeholder={placeholder}
        style={
          !errors
            ? style
            : errors === undefined
            ? { border: '1px solid #666' }
            : { border: '1px solid #e60014' }
        }
        onChange={onChange}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        {...register}
      />
    </div>
  );
};

export default ValidatedInputField;
