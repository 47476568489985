import React, { useState, Fragment, useEffect } from 'react';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import { responseMessages } from '../../utils/Helper/enums';
import SubmitFeedback from '../../utils/Feedback/Feedback';
import { wrapAsyncFunction } from '../UtilityFunction/wrapAsyncFunction';

interface editAdminUserProps {
  orgEmail: string | any;
  name: string | any;
}
// Re-usable component for updating Organization users for a specific Organization Admin
export const FeedbackForm: React.FC<editAdminUserProps> = ({ orgEmail, name }) => {
  // Interface for declaring data types
  interface formDetails {
    feedbackMessage?: string | null;
  }

  // ToDO: In later stages, we're planning to handle it from the popup.
  const [showForm, setShowForm] = useState<boolean>(false);
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently the db value.
  const [email, setEmail] = useState(orgEmail);
  const [userName, setUserName] = useState<string | any>(name);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  // Declaring state for handling errors.
  const [errors, setErrors] = useState<formDetails>({});
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);

  // Default typescript library to navigate to a certain path after user is created successfully
  const navigate = useNavigate();

  // Form validation code added to useState
  useEffect(() => {
    validateForm();
  }, [feedbackMessage]);

  useEffect(() => {
    setUserName(name);
  }, [name]);

  // Form validation code added here
  const validateForm = () => {
    const errors: formDetails = {};
    if (!feedbackMessage.trim()) {
      errors.feedbackMessage = 'Please provide your valuable feedback / query';
    }
    setErrors(errors);
  };

  const location = useLocation();
  const isAdmin = location.pathname === '/admin';
  const btAdmin = location.pathname === '/btadmin';
  const isUser = location.pathname === '/user';

  // Storing data from UI to db via promise
  const onSubmit: any = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    // To show the loader after submitting the form
    setTimeout(() => setShowLoader(false), 3000);
    validateForm();
    // Form submission code here
    const userObj = new SubmitFeedback({
      email,
      name: userName,
      feedback: feedbackMessage
    });

    // API responses have been merged since actions are same
    const feedbackSubmissionStatus = await userObj.submitFeedback();
    setEmail(email);
    setUserName(userName);
    setFeedbackMessage('');

    const errorMessage = responseMessages.SomethingWentWrong;
    const successMessage = responseMessages.FeedbackSubmitted;
    const response = handleApiResponse(
      feedbackSubmissionStatus.statusCode,
      errorMessage,
      successMessage
    );
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowAlert(true);

    if (btAdmin && feedbackSubmissionStatus.statusCode === 201) {
      navigate('/btadmin');
    } else if (isAdmin && feedbackSubmissionStatus.statusCode === 201) {
      navigate('/admin');
    } else if (isUser && feedbackSubmissionStatus.statusCode === 201) {
      navigate('/user');
    }
  };

  // Method to show the update user form on clicking the edit button
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  // Method to close the create user form on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShowForm(e);
    navigate(0);
  };

  const closeAllPopUp = () => {
    setShowForm(false);
    setShowAlert(false);
  };

  return (
    <Fragment>
      <Link
        className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
        to={''}
        onClick={handleButtonClick}
      >
        <span className="text-black"> Feedback / Query </span>
      </Link>

      {showForm && (
        <CustomPopup onClose={popupCloseHandler} show={showForm} width={'w-41%'}>
          <Form
            orgEmail={email}
            show={showAlert}
            showLoader={showLoader}
            errors={errors}
            onSubmit={wrapAsyncFunction(onSubmit)}
            setShowForm={closeAllPopUp}
            createUser={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
            isBtAdmin={false}
            usersEmail={''}
            isFeedback={true}
            feedbackMessage={feedbackMessage}
            setFeedbackMessage={setFeedbackMessage}
            userName={userName}
            setUserName={setUserName}
            message={responseMessage}
          />
        </CustomPopup>
      )}
    </Fragment>
  );
};
